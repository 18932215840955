import React, { useState, useEffect } from 'react';
import './App.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';


function App() {
  const refreshFromHistory = localStorage.getItem("_akrp_news_ref");
  const refreshTime = refreshFromHistory ? refreshFromHistory : "0";
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(refreshTime);
  const [intervalId, setIntervalID] = useState("0");

  const handleChange = (event: any) => {
    clearInterval(parseInt(intervalId));
    setRefresh(event.target.value);
    localStorage.setItem("_akrp_news_ref", event.target.value);
  };

  useEffect(() => {
      function getNews() {
        fetch(`/api/get`,
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/vnd.github.cloak-preview"
          })
        })
          .then(result => result.json())
          .then(response => {
            setNews(response);
            setIsLoading(false);
          })
          .catch(error => console.log(error));
      }
      if(refresh === "1") {
        getNews();
        const interval = setInterval(() => getNews(), 30*1000);
          setIntervalID(interval.toString());
      } else if(refresh === "2") {
        getNews();
        const interval = setInterval(() => getNews(), 60*1000);
          setIntervalID(interval.toString());
      } else if(refresh === "3") {
        getNews();
        const interval = setInterval(() => getNews(), 180*1000);
          setIntervalID(interval.toString());
      } else if(refresh === "4") {
        getNews();
        const interval = setInterval(() => getNews(), 300*1000);
          setIntervalID(interval.toString());
      }
  }, [refresh])

  useEffect(() => {
    if(refresh === "0") {
      fetch(
        `/api/get`,
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/vnd.github.cloak-preview"
          })
        }
      )
        .then(res => res.json())
        .then(response => {
          setNews(response);
          setIsLoading(false);
        })
        .catch(error => console.log(error));
    }
  }, [refresh]);

  return (
    <div className="App">
      <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className={"header"}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            F&O News
          </Typography>
            <FormControl component="fieldset">
              <FormLabel component="legend">Auto Refresh</FormLabel>
              <RadioGroup row aria-label="refresh" name="row-radio-buttons-group" value={refresh} onChange={handleChange}>
                <FormControlLabel value="0" control={<Radio />} label="None" />
                <FormControlLabel value="1" control={<Radio />} label="30secs" />
                <FormControlLabel value="2" control={<Radio />} label="1min" />
                <FormControlLabel value="3" control={<Radio />} label="3min" />
                <FormControlLabel value="4" control={<Radio />} label="5min" />
              </RadioGroup>
            </FormControl>
        </Toolbar>
        <div>
        
        </div>
        
      </AppBar>
    </Box>
      {isLoading && <p>Loading news...</p>}
      <div style={{padding: 20}}>
      {!isLoading && 
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {news.map((n: any, i:any) => {
        return  <Grid item xs={12} md={6} key={n.title+i}>
        <div>
          <div><b><a href={n.link} target="_blank" rel="noreferrer">{n.title}</a></b></div>
          <div><i>{n.description}</i></div>
          <div>Published: {new Date(n.published).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'})} </div><br/>
        </div>
      </Grid>

      })}
    </Grid>
      
      }
      </div>
    </div>
  );
}

export default App;